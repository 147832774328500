import { render, staticRenderFns } from "./e-button-document-upload.vue?vue&type=template&id=2abbf3b6&scoped=true&lang=pug&"
import script from "./e-button-document-upload.vue?vue&type=script&lang=js&"
export * from "./e-button-document-upload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2abbf3b6",
  null
  
)

export default component.exports