<template lang="pug">
  v-app
    template(v-if="xsDevice")
      _header(@toggleDrawer="toggleDrawer" :is-drawer-visible="drawer")
      drawer-mobile(
        :value="drawer"
        @closeDrawer="drawer = false"
      )
    _drawer(v-else)
    v-main
      v-container(fill-height fluid class="default pa-0 align-start bg")
        nuxt
    notification
    dialogs(ref="dialogs")
    sidebars
    snackbars
    guidelines
</template>

<script>
import notification from './includes/_notification'
import dialogs from './includes/_dialogs'
import _drawer from '~/layouts/includes/_drawer'
import _header from '~/layouts/includes/_header'
import sidebars from '~/modules/sidebar/views/components/sidebars'
import responsive from '~/mixins/pages/responsive'
import snackbars from '~/layouts/includes/snackbars'
import DrawerMobile from '~/layouts/includes/drawer-mobile'
import guidelines from '~/layouts/includes/guidelines'
import templateOnboarding from '~/modules/templates/mixins/templateOnboarding'

export default {
  components: {
    snackbars,
    _drawer,
    notification,
    dialogs,
    sidebars,
    _header,
    DrawerMobile,
    guidelines
  },
  mixins: [templateOnboarding, responsive],
  data () {
    return {
      drawer: false
    }
  },
  mounted () {
    this.checkShowTemplatesOnboarding()
  },
  methods: {
    toggleDrawer () {
      this.drawer = !this.drawer
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  min-width: 320px !important;
  overflow: hidden;
}
.bg {
  background: #F6F9FD;
}
</style>
